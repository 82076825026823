import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import {cancelAtPeriodEnd} from '../../data/api';
import SpinnerButton from './SpinnerButton';
import useToggleAutoBilling from '../../hooks/useToggleAutoBilling';

/**
 * @typedef {object} CancelAtPeriodEndProps
 * @property {boolean} isLoading
 * @property {object} subscription
 * @property {function} onCloseModal
 * @property {function} setLoadingState
 * @property {function} setErrors
 * @property {function} setIsLoading
 * @property {string?} currentPeriodEnd
 * @property {boolean} hasAutoBillingEnabled
 * @property {string} autoPriceId
 * @property {object} user
 */

/**
 * @param {CancelAtPeriodEndProps} props
 * @returns {JSX.Element}
 */
function CancelAtPeriodEnd({
    isLoading,
    subscription,
    onCloseModal,
    setLoadingState,
    setErrors,
    setIsLoading,
    currentPeriodEnd,
    hasAutoBillingEnabled,
    autoPriceId,
    user
}) {
    const buttonStates = {
        success: {
            className: 'gh-btn gh-btn-block gh-btn-red',
            html: '&#10004; Successfully cancelled!',
            disabled: true
        },
        fail: {
            className: 'gh-btn gh-btn-block gh-btn-red',
            html: 'Contact support@ghost.org',
            disabled: true
        },
        pending: {
            className: 'gh-btn gh-btn-red gh-btn-block spinner',
            html: '<span><div class="gh-spinner"></div></span>',
            disabled: true
        },
        default: {
            className: 'gh-btn gh-btn-block gh-btn-red',
            html: 'Cancel subscription',
            onSubmit: 'cancel',
            disabled: false
        }
    };

    const {handleToggleAutoBilling, status, error: autoBillingError} = useToggleAutoBilling(
        subscription,
        user,
        autoPriceId,
        'manual'
    );

    useEffect(() => {
        if (status === 'succeeded') {
            setIsLoading(false);
            setButtonState(buttonStates.success);
            setLoadingState(true);
            return onCloseModal({reload: true, delay: 1000});
        } else if (status === 'failed') {
            Sentry.withScope((scope) => {
                scope.setExtra('subscriptionId', subscription.id);
                scope.setTag('pointer', 'handleCancelAtPeriodEnd_handleAutoBillingError');
                Sentry.captureException(autoBillingError);
            });

            setIsLoading(false);
            setButtonState(buttonStates.fail);
            return setErrors('Something went wrong when trying to cancel. Refresh your browser and try again, or contact support@ghost.org');
        }
    }, [status, autoBillingError]); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Handles the cancellation of the account at the end of the subscription period
     * @param {React.MouseEvent<HTMLButtonElement>} event
     * @returns {Promise<void>}
     */
    const handleCancelAtPeriodEnd = async (event) => {
        event?.preventDefault();
        // Set loading state. Can only be done at this point, because the
        // Stripe card elements needs to be mounted until now
        setIsLoading(true);
        setButtonState(buttonStates.pending);

        try {
            const res = await cancelAtPeriodEnd({subscriptionId: subscription.id});

            if (res?.errors && !res?.ok) {
                Sentry.withScope((scope) => {
                    scope.setExtra('subscriptionId', subscription.id);
                    scope.setTag('pointer', 'handleCancelAtPeriodEnd_cancelAccount');
                    scope.setContext('cancelAccount', res);
                    Sentry.captureException(new Error(`Cancelling failed`));
                });

                setIsLoading(false);
                setButtonState(buttonStates.fail);
                return setErrors('Something went wrong when trying to cancel. Refresh your browser and try again, or contact support@ghost.org');
            } else {
                if (hasAutoBillingEnabled) {
                    // If auto billing is enabled, we need to disable it after
                    // the account is cancelled
                    await handleToggleAutoBilling(event);
                } else {
                    setIsLoading(false);
                    setButtonState(buttonStates.success);
                    setLoadingState(true);
                    return onCloseModal({reload: true, delay: 1000});
                }
            }
        } catch (error) {
            Sentry.withScope((scope) => {
                scope.setExtra('subscriptionId', subscription.id);
                scope.setTag('pointer', 'handleCancelAtPeriodEnd_cancelAccount');
                Sentry.captureException(error);
            });

            setIsLoading(false);
            setButtonState(buttonStates.fail);
            return setErrors('Something went wrong when trying to cancel. Refresh your browser and try again, or contact support@ghost.org');
        }
    };
    const [buttonState, setButtonState] = useState(buttonStates.default);

    /**
     * @param {React.MouseEvent<HTMLButtonElement>} event
     * @returns {Promise<void>}
     */
    const setSpinnerButtonAction = (event) => {
        if (buttonState?.disabled || !buttonState?.onSubmit) {
            return null;
        } else if (buttonState?.onSubmit === 'cancel') {
            return handleCancelAtPeriodEnd(event);
        }
    };

    const formattedDate = new Date(currentPeriodEnd).toLocaleDateString('en-US', {month: 'long', day: 'numeric', year: 'numeric'});

    return (
        <>
            <div className="cancel-account-warning">
                <p>If you cancel your subscription now, you will continue to have access until <strong>{formattedDate}</strong>.</p>

                <p>After that day your account and publication data will be permanently deleted. Remember to export your data before.</p>
            </div>

            <SpinnerButton
                onClick={event => setSpinnerButtonAction(event)}
                className={buttonState.className}
                html={buttonState.html}
                disabled={isLoading}
                data-test-btn="cancel-account"
            />
        </>
    );
}

CancelAtPeriodEnd.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    subscription: PropTypes.shape({
        id: PropTypes.string.isRequired,
        meta: PropTypes.shape({
            baseProduct: PropTypes.shape({
                stripe_price_id: PropTypes.string
            }).isRequired,
            addons: PropTypes.arrayOf(PropTypes.shape({
                stripe_price_id: PropTypes.string
            }))
        })
    }).isRequired,
    onCloseModal: PropTypes.func.isRequired,
    setLoadingState: PropTypes.func.isRequired,
    setErrors: PropTypes.func.isRequired,
    setIsLoading: PropTypes.func.isRequired,
    currentPeriodEnd: PropTypes.string.isRequired,
    hasAutoBillingEnabled: PropTypes.bool,
    autoPriceId: PropTypes.string,
    user: PropTypes.shape({
        id: PropTypes.string.isRequired,
        stripe_token: PropTypes.string
    }).isRequired
};

export default CancelAtPeriodEnd;
