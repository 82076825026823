import React from 'react';
import PropTypes from 'prop-types';
import {
    withRouter,
    Link
} from 'react-router-dom';
import {
    getCachedBillingData,
    getPartialBillingData,
    getBillingData,
    fetchPaymentMethod,
    changeOrActivateSubscriptionPlan,
    getInvoicePreview,
    getForceUpgrade,
    getLatestInvoice
} from '../data/api';
import {loadStripe} from '@stripe/stripe-js';
import * as Sentry from '@sentry/react';

import formatNumber from '../utils/format-number';
import formatPeriod from '../utils/format-period';
import {
    monthlyPrice,
    yearlyPrice
} from '../utils/price-for-period';
import {
    findNearestLimit,
    findSuitablePrices
} from '../utils/member-limits';
import checkLimits from '../utils/check-limits';
import {
    getMembersIncluded,
    findComparablePrice,
    getMonthlyEquivalentPrice,
    getAnnualDiscount,
    getTotalPrice,
    getCouponDiscount
} from '../utils/billing-utils';

import SpinnerButton from './shared/SpinnerButton';
import InvoiceDetailsModal from './shared/InvoiceDetailsModal';
import UpdateCardModal from './stripe/UpdateCardModal';

const sleep = ms => (
    new Promise((resolve) => {
        setTimeout(resolve, ms);
    })
);
class Checkout extends React.Component {
    _verbose = (process?.env?.REACT_APP_VERBOSE && process?.env?.REACT_APP_VERBOSE === 'true') || false;
    _isMounted = false;
    stripe = null;

    buttonStates = {
        success: {
            className: 'gh-btn gh-btn-block gh-btn-green',
            html: '&#10004; Update complete!',
            disabled: true
        },
        warning: {
            className: 'gh-btn gh-btn-block gh-btn-green',
            html: 'Done',
            disabled: false
        },
        fail: {
            className: 'gh-btn gh-btn-block',
            html: 'Try again',
            onSubmit: e => this.confirmAndSubmit(e),
            disabled: false
        },
        pending: {
            className: 'gh-btn gh-btn-green gh-btn-block spinner',
            html: '<span><div class="gh-spinner"></div></span>',
            disabled: true
        },
        default: {
            className: 'gh-btn gh-btn-green gh-btn-block',
            html: 'Confirm plan selection',
            onSubmit: e => this.confirmAndSubmit(e),
            disabled: false
        },
        scaRetry: {
            className: 'gh-btn gh-btn-block',
            html: 'Complete payment now',
            onSubmit: e => this.confirmAndSubmit(e),
            disabled: false
        }
    };

    constructor(props) {
        super(props);

        if (props) {
            props.showNav(false);
        }

        // initial state
        this.state = {
            suitablePrice: null,
            selectedAddons: [],
            availableAddons: [],
            comparablePrice: null,
            subscription: null,
            period: null,
            loading: true,
            errors: null,
            amountDue: 0,
            endingBalance: 0,
            updateCardModalIsOpen: false,
            invoiceDetailsModalIsOpen: false,
            buttonState: this.buttonStates.default,
            btnDisabled: false,
            isSCARetry: false,
            isPendingOrIncompletePrice: false

        };

        this.togglePeriod = this.togglePeriod.bind(this);
        this.addAddonToCheckout = this.handleAddonAdded.bind(this);
        this.removeAddonFromCheckout = this.handleAddonRemoved.bind(this);
        this.toggleAddonDetails = this.toggleAddonDetails.bind(this);
        this.confirmAndSubmit = this.handleConfirmAndSubmit.bind(this);
        this.getAmountDue = this.getAmountDue.bind(this);
        this.ensureStripeLoaded = this.ensureStripeLoaded.bind(this);
        this.handleOpenUpdateCardModal = this.handleOpenUpdateCardModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleOpenInvoiceDetailsModal = this.handleOpenInvoiceDetailsModal.bind(this);
        this.handleNestedLoadingState = this.handleNestedLoadingState.bind(this);
        this.delayedReturnToDashboard = this.delayedReturnToDashboard.bind(this);
        this.handlePaymentSCA = this.handlePaymentSCA.bind(this);
        this.doSCAPayment = this.doSCAPayment.bind(this);
        this.setPendingOrIncompletePrice = this.setPendingOrIncompletePrice.bind(this);
    }

    async componentDidMount() {
        let suitablePrice;
        let limitsValidations = [];
        const paramsRegex = /^(\w*)(?:-)(\w*)(?:-)(\w*)?$/gmi;
        this.props.updateTitle('Confirm your plan selection');

        this._isMounted = true;
        this.stripe = this.ensureStripeLoaded();

        const scope = Sentry.getCurrentScope();
        scope.setTransactionName('Checkout');

        const param = this.props.match.params.id;

        if (!param || param.indexOf('personal') >= 0) {
            return this.props.history.push('/');
        }

        const parsedParam = paramsRegex.exec(param);
        const [, selectedProduct, selectedGroup, selectedPeriod] = parsedParam;

        this.setState({
            selectedProduct,
            period: selectedPeriod,
            selectedGroup,
            buttonState: {...this.buttonStates.default, disabled: true}
        });

        const billingData = await getCachedBillingData();

        Sentry.setUser({email: billingData?.user?.email_address});
        Sentry.setContext('billingData', {
            status: billingData?.subscription?.status,
            price: billingData?.currentPrice?.nickname,
            site: billingData?.currentSite?.url,
            exceededLimits: billingData?.exceededLimits,
            forceUpgrade: billingData?.isForceUpgrade,
            param: parsedParam
        });
        Sentry.setTag('section', 'checkout');

        if (billingData.isGrace) {
            // Return to Dashboard when user is in dunning to update card details
            return this.props.history.push('/');
        }

        // Ensure we always load fresh limit data from Daisy in case anything has changed (e. g. theme or number of staff)
        const currentSiteLimits = await getPartialBillingData('currentSiteLimits');

        if (this._isMounted) {
            const hasCard = billingData?.user?.cardType;
            let buttonState = {...this.buttonStates.default, disabled: !hasCard};
            let baseProductAddons = [];
            let selectedAddons = [];
            let autoPriceAddon = null;
            const hasSubdirectory = billingData?.currentSite?.subdirectory;
            const hasCustomSendingDomain = billingData?.currentSite?.sending_domain_status === 'VERIFIED';

            if (
                !billingData?.isIncomplete && !billingData?.isAutoBillingGrace
                && (!billingData || billingData?.errors || !billingData?.availableProducts)
            ) {
                return this.props.history.push('/');
            }

            const selectedBaseProduct = billingData?.availableProducts.find((product) => {
                return product.name.toLowerCase() === selectedProduct.toLowerCase() && product.group === selectedGroup;
            });

            const entryProduct = billingData?.availableProducts?.[0]?.name?.toLowerCase();

            if (!selectedBaseProduct) {
                if (this._verbose) {
                    console.log('[CHECKOUT] Product not available or doesn\'t exist:', selectedProduct);
                }
                return this.props.history.push('/plans');
            }

            if (hasCustomSendingDomain && selectedProduct === entryProduct && billingData?.currentPrice?.base_product.toLowerCase() !== entryProduct) {
                return this.props.history.push('/plans');
            }

            if (hasSubdirectory && selectedBaseProduct?.addons?.length) {
                if (this._verbose) {
                    console.log(`[CHECKOUT] Subdirectory addon available in ${selectedBaseProduct?.name} product.`);
                }
                const subdirectoryAddon = selectedBaseProduct.addons.filter(addon => addon.name === 'Subdirectory install');
                if (subdirectoryAddon.length) {
                    baseProductAddons.push(subdirectoryAddon[0]);
                }
            }

            if (billingData?.hasAutoBillingEnabled && selectedBaseProduct?.addons?.length) {
                // Customer has auto price add on. We need to add it to the selected addons
                if (this._verbose) {
                    console.log(`[CHECKOUT] Auto price addon available and added for ${selectedBaseProduct?.name} product.`);
                }

                autoPriceAddon = selectedBaseProduct.addons.filter(addon => addon.name.match(/^autobilling/i))?.[0];
            }

            const currentMembers = billingData?.currentSiteLimits?.members?.total || 500;
            const memberLimit = findNearestLimit(currentMembers, billingData?.availableProducts);

            if (baseProductAddons && hasSubdirectory) {
                if (this._verbose) {
                    console.log('[CHECKOUT] Subdirectory addon required');
                }
                // pre-select the subdirectory add-on, if the current site has a subdirectory enabled
                selectedAddons = baseProductAddons;
            }

            if (this._verbose) {
                console.log('[CHECKOUT] Selected base product: ', selectedBaseProduct?.name);
            }

            let suitablePrices = findSuitablePrices(selectedBaseProduct?.prices, memberLimit, selectedPeriod);

            if (suitablePrices && suitablePrices.length && currentSiteLimits) {
                // The prices are already sorted by member limits, so we can safely pick the first one
                suitablePrice = suitablePrices[0];

                // Check any suitable price against limits that would affect the price
                limitsValidations = checkLimits(suitablePrice, {
                    currentSiteLimits,
                    product: selectedBaseProduct?.name,
                    productLimits: selectedBaseProduct?.limits,
                    verbose: this._verbose
                });
            }

            // Return to plans if we couldn't find a suitable price in the current product
            if (!suitablePrices || !suitablePrices.length || (limitsValidations && limitsValidations.length)) {
                return this.props.history.push('/plans');
            }

            if (this._verbose) {
                console.log(`[CHECKOUT] Suitable price for ${currentMembers} members: `, suitablePrice);
            }

            let showAddonStates = {};

            if (baseProductAddons?.length) {
                // set the details toggle to closed by default
                baseProductAddons.forEach(({name}) => showAddonStates[name] = false);
            }

            const comparablePrice = findComparablePrice(selectedBaseProduct.prices, suitablePrice);

            // For convenience reasons, add the members limit as a property to state
            const membersIncluded = getMembersIncluded(suitablePrice);

            // We got all the information we need to start with. Let's set it in state
            this.setState({
                ...billingData,
                _invoiceDetails: billingData.invoiceDetails,
                selectedBaseProduct,
                availableAddons: baseProductAddons || [],
                suitablePrice,
                comparablePrice,
                autoPriceAddon,
                amountDue: 0,
                showAddon: showAddonStates,
                selectedAddons,
                selectedAddonsPrices: [],
                membersIncluded,
                period: suitablePrice.billing_period,
                loading: false,
                buttonState,
                hasSubdirectory,
                errors: !hasCard ? 'Add a card to continue' : null
            });

            // If we have detected a pending subscription, check if we're in the current price and
            // which state it is (incomplete or pending)
            if (this.state?.pendingSubscription) {
                const scaRetryState = {isSCARetry: true};

                // A subscription in an incomplete state should not be able to change around anymore.
                // We need to disable all other buttons and clearly signal to finalise the payment with SCA.
                if (billingData.isIncomplete || billingData.isAutoBillingGrace) {
                    scaRetryState.amountDue = getTotalPrice({cost: this.state?.suitablePrice?.cost, period: this.state.period, selectedAddons: this.state?.selectedAddons});
                    scaRetryState.btnDisabled = true;

                    this.setState(scaRetryState);

                    // Change the URL parameter to reflect the new selection if we need to
                    if (window.location.pathname !== this.state.checkoutRoute) {
                        return this.props.history.push(`/checkout/${this.state.checkoutRoute}`);
                    }
                }

                this.setPendingOrIncompletePrice();

                if (this.state.isPendingOrIncompletePrice) {
                    this.props.updateTitle('Complete payment authorization');

                    buttonState = this.buttonStates.scaRetry;

                    this.setState(scaRetryState);
                }
            }

            if (
                !this.state.isPendingOrIncompletePrice
                && this.state.suitablePrice?.stripe_price_id === this.state?.currentPrice?.stripe_price_id
            ) {
                return this.setState({
                    errors: 'This is your current plan. Choose a different period or plan.',
                    buttonState: {...this.buttonStates.default, disabled: true}
                });
            } else {
                this.setState({
                    buttonState: {...this.buttonStates.default, disabled: !billingData?.user?.cardType},
                    amountDue: getTotalPrice({cost: this.state?.suitablePrice.cost, period: this.state.period, selectedAddons: this.state?.selectedAddons})
                });

                // getAmountDue requires state to have subscription info already
                await this.getAmountDue(suitablePrice.stripe_price_id, suitablePrice.billing_period);
            }
        }
    }

    componentWillUnmount() {
        this.props.updateTitle('Billing and domain settings');
        this.props.showNav(true);
        this._isMounted = false;
    }

    handleOpenUpdateCardModal(value) {
        this.setState({updateCardModalIsOpen: value});
    }

    handleOpenInvoiceDetailsModal(value) {
        this.setState({invoiceDetailsModalIsOpen: value});
    }

    async handleCloseModal(options = {}) {
        const reload = options?.reload || false;
        const delay = options?.delay || 0;

        this.setState({
            updateCardModalIsOpen: false,
            invoiceDetailsModalIsOpen: false
        });

        if (reload && reload === true) {
            this.props.showNav(false);

            this.setState({
                loading: true,
                errors: null,
                buttonState: this.buttonStates.pending,
                btnDisabled: false
            });

            try {
                const billingData = await getBillingData();

                this.setState({
                    ...billingData,
                    _invoiceDetails: billingData.invoiceDetails,
                    buttonState: {...this.buttonStates.default, disabled: !billingData?.user?.cardType}
                });

                const currentPrice = await getPartialBillingData('currentPrice');

                const newState = {
                    ...currentPrice,
                    loading: false
                };

                await sleep(delay);

                this.setState(newState);
            } catch (error) {
                const billingData = this.state;

                Sentry.withScope((scope) => {
                    scope.setExtra('billingData', billingData);
                    scope.setExtra('pointer', 'handleCloseModal');
                    scope.setFingerprint(['handle-close-modal', 'catch-error-reload-billing-data']);
                    Sentry.captureException(error);
                });
                return this.setState({
                    errors: 'Something went wrong. Refresh the page and try again or contact support@ghost.org',
                    buttonState: this.buttonStates.fail,
                    loading: false
                });
            }
        } else {
            try {
                const billingData = await getCachedBillingData();

                await sleep(delay);

                this.setState(state => ({
                    ...billingData,
                    _invoiceDetails: billingData.invoiceDetails,
                    buttonState: state.isPendingOrIncompletePrice
                        ? this.buttonStates.scaRetry
                        : {...this.buttonStates.default, disabled: !billingData?.user?.cardType},
                    errors: state.errors && (state.errors.match(/add a card/i) && !billingData?.user?.cardType) ? state.errors : null
                }));
            } catch (error) {
                const billingData = this.state;

                Sentry.withScope((scope) => {
                    scope.setExtra('billingData', billingData);
                    scope.setExtra('pointer', 'handleCloseModal_cached');
                    scope.setFingerprint(['handle-close-modal', 'catch-error-cached-billing-data']);
                    Sentry.captureException(error);
                });
                return this.setState({
                    errors: 'Something went wrong. Refresh the page and try again or contact support@ghost.org',
                    buttonState: this.buttonStates.fail,
                    loading: false
                });
            }
        }
    }

    // A pending update subscription can be overwritten by choosing a different plan and might expire without
    // taking effect. The customer needs to be able to switch to a different product, or period, but we still
    // need to notify them to finalise the payment in order to complete the purchase.
    // We only wanna do this notification when we're currently on the correct plan and period that the
    // customer left off when leaving the screen after a failed SCA.
    setPendingOrIncompletePrice() {
        if (this.state.pendingSubscription?.priceId === this.state.suitablePrice?.stripe_price_id
            || (
                (this.state.isIncomplete || this.state.isAutoBillingGrace)
                && this.state?.pendingSubscription?.product?.toLowerCase() === this.state?.selectedBaseProduct?.name?.toLowerCase()
                && this.state?.pendingSubscription?.group === this.state?.selectedGroup
                && this.state?.pendingSubscription?.period === this.state?.period
            )
        ) {
            if (
                // Members amount changed between attempted trial -> sub conversion, so the price is not the correct one anymore.
                // Overwrite the current suitable price, to make sure we checkout the correct one.
                (this.state.isIncomplete || this.state.isAutoBillingGrace)
                && this.state?.pendingSubscription?.product?.toLowerCase() === this.state?.selectedBaseProduct?.name?.toLowerCase()
                && this.state?.pendingSubscription?.group === this.state?.selectedGroup
                && this.state?.pendingSubscription?.period === this.state?.period
            ) {
                const suitablePrice = this.state.selectedBaseProduct?.prices.find(price => price.stripe_price_id === this.state.pendingSubscription?.priceId);
                const membersIncluded = getMembersIncluded(suitablePrice);

                this.setState({
                    suitablePrice,
                    membersIncluded
                });
            }
            return this.setState({
                isPendingOrIncompletePrice: true
            });
        } else {
            return this.setState({
                isPendingOrIncompletePrice: false
            });
        }
    }

    async handlePaymentSCA(
        customerId = this.state?.subscription?.customer_id || this.state?.user?.stripe_token,
        clientSecret,
        paymentMethodId
    ) {
        let scaResult = {};

        // Safety guard to ensure we have the Stripe customer ID
        if (!customerId) {
            const {subscription, user} = await getBillingData();

            // There's no hope, can't proceed without the Stripe customer ID
            if (
                (!subscription && !subscription.customer_id) ||
                (!user && !user.stripe_token)
            ) {
                return this.props.history('/');
            }

            customerId = subscription?.customer_id || user?.stripe_token;
        }

        this.setState({
            buttonState: this.buttonStates.pending,
            errors: null,
            btnDisabled: true
        });

        try {
            if (!this.state.isSCARetry && clientSecret && paymentMethodId) {
                // This should only be done on-session and before any failure
                this.setState({isSCARetry: false});

                // use the client secret to confirm the card payment on-session
                scaResult = await this.doSCAPayment(clientSecret, paymentMethodId);
            } else {
                if (!clientSecret || this.state.isPendingOrIncompletePrice) {
                    // Fetch client_secret to initiate SCA payment
                    const invoice = await getLatestInvoice({customerId});

                    clientSecret = invoice?.payment_intent?.client_secret;
                }

                if (!paymentMethodId || this.state.isPendingOrIncompletePrice) {
                    // Fetch payment method to initiate SCA payment.
                    const paymentMethod = await fetchPaymentMethod({customerId});
                    paymentMethodId = paymentMethod.paymentMethodId;
                }

                this.setState({isSCARetry: false});

                scaResult = await this.doSCAPayment(clientSecret, paymentMethodId);
            }
        } catch (error) {
            Sentry.captureException(error, {
                tags: {
                    pointer: 'handlePaymentSCA'
                }
            });
            return this.setState({
                errors: 'We weren\'t able to process your payment. Try again or choose a different card.',
                buttonState: this.buttonStates.fail,
                isSCARetry: true,
                btnDisabled: false
            });
        }

        // The payment SCA was a success, proceed back to Dashboard, but first
        // grab the updated subscription information
        if (scaResult && scaResult?.paymentIntent?.status === 'succeeded') {
            if (this._verbose) {
                console.time('[CHECKOUT] SCA success, delay fetching subscription');
            }

            try {
                // We're done with the checkout, don't need that anymore.
                window.localStorage.removeItem(`gh_checkout_${customerId}`);
            } catch (error) {
                console.error('Unable to remove item from local storage', error);
            }

            this.props.showNav(false);

            this.setState({
                loading: true,
                btnDisabled: true,
                pendingSubscription: null,
                isPendingOrIncompletePrice: false,
                isSCARetry: false,
                isIncomplete: false,
                isPending: false
            });

            // Wait for a bit before fetching the updated subscription data.
            // This should give Stripe and Daisy enough time to process webhooks
            // and receive the updated subscription state after a customer was in
            // dunning.
            await sleep(7000);
            // force update the subscription information
            await getBillingData({sca: 'succeeded', updateAdmin: true});
            await getPartialBillingData('currentProduct');
            await getPartialBillingData('invoices', {userId: this.state?.user?.id});
            await getPartialBillingData('currentSite', {data: this.state.blogs});

            if (this._verbose) {
                console.timeEnd('[CHECKOUT] SCA success, delay fetching subscription');
            }
            await this.handleCloseModal({reload: false});

            this.props.updateTitle('Billing and domain settings');
            this.props.showNav(true);

            return this.props.history.replace('/', {});
        } else {
            const billingData = await getBillingData();

            this.setState({
                ...billingData,
                errors: 'We weren\'t able to process your payment. Try again or choose a different card.',
                buttonState: this.buttonStates.fail,
                isSCARetry: true
            });

            if (billingData?.isIncomplete || billingData.isAutoBillingGrace) {
                return this.setState({btnDisabled: true});
            } else if (billingData?.isPending) {
                return this.setState({btnDisabled: false});
            }
        }
    }

    // This will trigger the SCA modal to confirm the card payment on-session
    async doSCAPayment(clientSecret, paymentMethodId) {
        const stripe = await this.stripe;

        if (!stripe) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        // confirm the card payment
        return await stripe.confirmCardPayment(clientSecret, {
            payment_method: paymentMethodId
        });
    }

    handleNestedLoadingState(loading) {
        this.setState({loading});
    }

    async delayedReturnToDashboard() {
        if (this._verbose) {
            console.time('[CHECKOUT] Delay and return to Dashboard');
        }
        // Ghost will restart after a change in subscription to apply the new
        // limits. Average boot time should be around 3.5sec
        return setTimeout(async () => {
            if (this._verbose) {
                console.timeEnd('[CHECKOUT] Delay and return to Dashboard');
            }

            try {
                await getPartialBillingData('users', {userId: this.state?.user?.id});
                await getPartialBillingData('invoices', {userId: this.state?.user?.id});
                await getPartialBillingData('currentSite', {data: this.state.blogs});
            } catch (error) {
                if (this._verbose) {
                    console.log('[BMA] Error receiving invoice and currentSite:', error);
                }
                // noop
            }

            await this.handleCloseModal({reload: false});

            // When the site was in a forceUpgrade state before
            // we need to ensure to update our data after Ghost
            // has the updated config value
            if (this.state?.forceUpgradeOwner) {
                const billingData = await getForceUpgrade();

                if (this._verbose) {
                    console.log('[CHECKOUT] Updated billingData:', billingData);
                }
            }

            this.props.updateTitle('Billing and domain settings');

            return this.props.history.replace('/', {});
        }, 7000);
    }

    async togglePeriod(period) {
        if (this._verbose) {
            console.log('[CHECKOUT] Selected period:', period);
        }

        if (this.state.suitablePrice.billing_period !== period) {
            let buttonState = this.state.isPendingOrIncompletePrice ? this.buttonStates.scaRetry : {...this.buttonStates.default, disabled: !this.state?.user?.cardType};

            this.setState({
                // Set both to zero to make sure the UI is updated and we don't show the proration text
                amountDue: 0,
                endingBalance: 0,
                startBalance: 0,
                proration: 0,
                errors: !this.state.user?.cardType ? 'Add a card to continue' : null
            });

            // Change the URL parameter to reflect the new selection
            this.props.history.push(`/checkout/${this.state.selectedBaseProduct.name.toLowerCase()}-${this.state.selectedGroup}-${period}`);

            // ensure the state is correctly set before proceeding
            await this.setState(state => ({
                period: period,
                suitablePrice: state.comparablePrice,
                comparablePrice: state.suitablePrice,
                buttonState
            }));

            if (this.state.suitablePrice?.stripe_price_id === this.state?.currentPrice?.stripe_price_id) {
                return this.setState({
                    errors: 'This is your current plan. Choose a different period or plan.',
                    buttonState: {...this.buttonStates.default, disabled: true}
                });
            } else {
                await this.setState({buttonState});

                this.setPendingOrIncompletePrice();

                await this.getAmountDue(this.state.suitablePrice.stripe_price_id, period);
            }
        }
    }

    toggleAddonDetails(name) {
        const addonState = this.state.showAddon;
        addonState[name] = !addonState[name];
        this.setState({addonState});
    }

    async ensureStripeLoaded() {
        return await loadStripe(process?.env?.REACT_APP_STRIPE_PK);
    }

    async getAmountDue(price, period) {
        let addonPrices = [];
        let buttonState = this.state.isPendingOrIncompletePrice ? this.buttonStates.scaRetry : {...this.buttonStates.default, disabled: !this.state?.user?.cardType};
        const totalNow = getTotalPrice({cost: this.state?.suitablePrice.cost, period, selectedAddons: this.state?.selectedAddons, coupon: this.state?.discount?.coupon});

        if (this.state?.isTrial || this.state?.forceUpgradeOwner) {
            // there's no pro-rated credit for a trial -> subscription conversion
            // or for a lapsed trial/subscription when in forceUpgrade state
            return this.setState({amountDue: totalNow});
        }

        this.setState({buttonState: {...buttonState, disabled: true}});

        if (this.state?.selectedAddons) {
            this.state.selectedAddons.map((addonsSelected) => {
                const addon = {};
                addonsSelected.prices.forEach((addonPrice, i) => {
                    if (addonPrice.billing_period === period) {
                        return addon.selected = addonPrice.stripe_price_id;
                    }
                    return addon.current = addonPrice.stripe_price_id;
                });

                return addonPrices.push(addon);
            });
        }

        if (this.state?.autoPriceAddon) {
            const addon = {};
            this.state.autoPriceAddon?.prices.forEach((addonPrice) => {
                if (addonPrice.billing_period === period) {
                    return addon.selected = addonPrice.stripe_price_id;
                }
                // Current always needs to be the currently active auto price id
                return addon.current = this.state?.autoPriceId;
            });

            addonPrices.push(addon);
        }

        const {amount, endingBalance, prorationDate, startBalance} = await getInvoicePreview({
            customerId: this.state.subscription.customer_id,
            basePrices: {
                current: this.state?.currentPrice?.stripe_price_id,
                selected: price || this.state.suitablePrice.stripe_price_id
            },
            addonPrices,
            subscriptionId: this.state.subscription.stripe_subscription_id,
            period: period || this.state.period,
            couponId: this.state?.discount?.coupon?.id
        });

        // Basic math here because it's almost impossible to recreate the numbers
        // with what Stripe returns, mostly when switching between billing periods.

        // Normal situation: we calculate the credit from last time by simply taking
        // the ending balance and subtracting the total that we want to charge now.
        // That way the number is guaranteed to be correct and can be reconstructed
        // by the user.
        let proration = endingBalance - totalNow;

        // In a case where the whole prorated credit will be used up, the above calculation
        // doesn't work. We now need to use the amount property (which is the total that Stripe
        // will charge) and subtract it from our total. The result as negative is what makes the
        // calculation explainable.
        if (endingBalance === 0 && startBalance <= 0) {
            proration = (totalNow - amount) * -1;
        }

        this.setState({
            amountDue: amount ? amount.toFixed(2) : 0,
            endingBalance: endingBalance ? endingBalance.toFixed(2) : 0,
            proration: proration ? proration.toFixed(2) : 0,
            prorationDate,
            buttonState
        });
    }

    handleAddonAdded(addon) {
        let selectedAddonsPrices;
        let selectedAddonsArray = this.state.selectedAddons;

        selectedAddonsArray.push(addon);

        if (this.state?.selectedAddons) {
            selectedAddonsPrices = selectedAddonsArray.map((addonsSelected) => {
                return addonsSelected.prices.filter((price) => {
                    return price.billing_period === this.state.period;
                })[0];
            });
        }

        this.setState({
            selectedAddons: selectedAddonsArray,
            selectedAddonsPrices
        });
    }

    handleAddonRemoved(addon) {
        let selectedAddonsPrices;
        let selectedAddonsArray = this.state.selectedAddons;

        selectedAddonsArray = selectedAddonsArray.filter(addonSelected => addonSelected.name !== addon.name);

        if (this.state?.selectedAddons) {
            selectedAddonsPrices = selectedAddonsArray.map((addonsSelected) => {
                return addonsSelected.prices.filter((price) => {
                    return price.billing_period === this.state.period;
                })[0];
            });
        }

        this.setState({
            selectedAddons: selectedAddonsArray,
            selectedAddonsPrices
        });
    }

    async handleConfirmAndSubmit(event) {
        event.preventDefault();

        let deleteAutoPrice = false;
        const customerId = this.state.subscription.customer_id ?? this.state.user.stripe_token;
        const addonIds = [];

        this.setState({
            buttonState: this.buttonStates.pending,
            errors: null,
            btnDisabled: true
        });

        // when dealing with an incomplete subscription or a retry of a failed
        // SCA confirmation, we can't submit the subscription changes again and
        // need to finalise the outstanding payment.
        if (this.state?.isPendingOrIncompletePrice) {
            if (this._verbose) {
                console.log('[CHECKOUT] Pending or incomplete price, handle SCA payment');
            }
            return this.handlePaymentSCA(customerId);
        }

        if (this.state?.suitablePrice?.stripe_price_id === this.state?.currentPrice?.stripe_price_id) {
            return this.setState({
                buttonState: this.buttonStates.fail,
                errors: 'This is your current plan. Choose a different period or plan.',
                btnDisabled: false
            });
        }

        if (this.state?.selectedAddons?.length) {
            this.state.selectedAddons.map((addon) => {
                const addonPrice = addon.prices.filter(price => price.billing_period === this.state.period)[0];

                return addonIds.push(addonPrice.stripe_price_id);
            });
        }

        if (this.state?.autoPriceAddon) {
            const addonPrice = this.state?.autoPriceAddon.prices.filter(price => price.billing_period === this.state.period)[0];
            addonIds.push(addonPrice.stripe_price_id);
        } else if (!this.state?.autoPriceAddon && this.state?.billingData?.hasAutoBillingEnabled) {
            // CASE: The selected plan doesn't support auto price addons but the user
            // has this addon currently enabled.
            // We need to first remove the auto price in a separate call
            // before we can change to the new selected plan.
            deleteAutoPrice = true;
        }

        // Ensure we always load fresh limit data from Daisy in case anything has changed (e. g. theme or number of staff)
        const currentSiteLimits = await getPartialBillingData('currentSiteLimits');

        // Check the current limits against the limits of the new price and product
        // and avoid changing if not suitable
        const limitsValidations = checkLimits(this.state.suitablePrice, {
            currentSiteLimits,
            product: this.state.selectedBaseProduct?.name,
            productLimits: this.state.selectedBaseProduct?.limits,
            verbose: this._verbose
        });

        // Disable the price if any of the limits are reached (product or price)
        if (limitsValidations && limitsValidations.length >= 1) {
            return this.setState({
                buttonState: this.buttonStates.fail,
                errors: limitsValidations[0].message,
                btnDisabled: false
            });
        }

        const {paymentMethodId, errors: paymentErrors} = await fetchPaymentMethod({customerId});

        if (!paymentErrors && paymentMethodId) {
            this.setState({
                errors: null
            });

            const data = {
                customerId,
                subscriptionId: this.state.subscription.id,
                isTrial: this.state.isTrial,
                userId: this.state.user.id,
                prorationDate: this.state.prorationDate,
                paymentMethodId
            };

            try {
                // Remove auto price first if needed
                if (deleteAutoPrice) {
                    if (this._verbose) {
                        console.log('[CHECKOUT] → Removing auto price addon');
                    }

                    data.planId = this.state?.subscription.meta.baseProduct.stripe_price_id;

                    const removedAutoPriceResponse = await changeOrActivateSubscriptionPlan(data);

                    // Wait for 2sec to ensure everything is updated in Stripe and Daisy
                    await sleep(2000);

                    if (removedAutoPriceResponse?.errors && removedAutoPriceResponse?.errors?.message?.indexOf('Error fetching invoices') < 0) {
                        return this.setState({
                            errors: removedAutoPriceResponse?.errors?.message || 'Could not remove autobilling. Switch to manual billing mode and try again.'
                        });
                    }
                }

                // Change to the new price
                data.planId = this.state.suitablePrice.stripe_price_id;
                data.addonIds = addonIds;

                const changePlanResponse = await changeOrActivateSubscriptionPlan(data);

                if (this._verbose) {
                    console.log('[CHECKOUT] → changePlanResponse', changePlanResponse);
                }

                // Ignore errors from fetching invoices, as they're not crucial to the flow and
                // can be caused by the site not being back up yet.
                if (changePlanResponse?.errors && changePlanResponse?.errors?.message?.indexOf('Error fetching invoices') < 0) {
                    return this.setState({
                        errors: changePlanResponse?.errors?.message || 'Could not process your card. Try again or contact support@ghost.org',
                        buttonState: this.buttonStates.fail,
                        btnDisabled: false
                    });
                }

                // Changing or activating a plan requires additional payment verification (SCA/3DS) and is set into
                // a pending or incomplete state.
                if (
                    changePlanResponse.isIncomplete
                    || changePlanResponse.isPending
                    || changePlanResponse?.isAutoBillingGrace
                ) {
                    // Remember the selected product and period in case the user ends
                    // in a pending update state and we need to pick up where we stopped.
                    const pendingSubscription = {
                        product: this.state?.selectedProduct?.toLowerCase(),
                        period: this.state?.period,
                        group: this.state?.selectedGroup,
                        priceId: this.state?.suitablePrice?.stripe_price_id
                    };

                    try {
                        window.localStorage.setItem(`gh_checkout_${customerId}`, JSON.stringify(pendingSubscription));
                    } catch (error) {
                        Sentry.captureException(error, {
                            tags: {
                                pointer: 'set_localStorage'
                            }
                        });
                        console.error('Unable to write to local storage', error);
                    }

                    this.setState({
                        pendingSubscription,
                        ...changePlanResponse,
                        isPendingOrIncompletePrice: true
                    });
                    // We need to do SCA for the payment
                    if (changePlanResponse?.subscription?.meta?.paymentIntentSecret && paymentMethodId) {
                        // use the client secret delivered as meta information to confirm the card payment on-session
                        return this.handlePaymentSCA(changePlanResponse?.subscription?.customer_id, changePlanResponse?.subscription?.meta?.paymentIntentSecret, paymentMethodId);
                    } else {
                        // handlePaymentSCA will now try to fetch the client_secret and payment method ID from Stripe directly
                        return this.handlePaymentSCA(changePlanResponse?.subscription?.customer_id);
                    }
                } else {
                    try {
                        // We're done with the checkout, don't need that anymore.
                        window.localStorage.removeItem(`gh_checkout_${customerId}`);
                    } catch (error) {
                        Sentry.captureException(error, {
                            tags: {
                                pointer: 'remove_localStorage'
                            }
                        });
                        console.error('Unable to remove item from local storage', error);
                    }

                    return this.delayedReturnToDashboard();
                }
            } catch (error) {
                Sentry.captureException(error, {
                    tags: {
                        pointer: 'handleConfirmAndSubmit'
                    }
                });
                return this.setState({
                    errors: error?.message || 'Could not process your card. Try again or contact support@ghost.org',
                    buttonState: this.buttonStates.fail,
                    btnDisabled: false
                });
            }
        } else {
            return this.setState({
                buttonState: this.buttonStates.fail,
                errors: 'Card authentication failed. Try again or contact support@ghost.org',
                btnDisabled: false
            });
        }
    }

    render() {
        if (!this.state?.loading) {
            let annualDiscount = getAnnualDiscount(this.state?.comparablePrice ?? this.state?.suitablePrice, this.state?.suitablePrice);
            let selectedAddons;
            let availableAddons;

            // List the add-ons for the billing summary
            if (this.state?.selectedAddons?.length) {
                selectedAddons = this.state.selectedAddons.map((addonsSelected) => {
                    const selectedAddonsPrices = addonsSelected.prices.filter((price) => {
                        return price.billing_period === this.state.period;
                    })[0];

                    let price = this.state.period === 'month' ? formatNumber(monthlyPrice(selectedAddonsPrices.cost, selectedAddonsPrices.billing_period), {symbol: '$'}) : formatNumber(yearlyPrice(selectedAddonsPrices.cost, selectedAddonsPrices.billing_period), {symbol: '$'});

                    return (
                        <div className="grey" key={addonsSelected.name}>
                            <span>{addonsSelected.name}</span>
                            <span>{price}</span>
                        </div>
                    );
                });
            }

            // List all available add-ons, but show a 'Remove' button if one of the add-ons has already been selected
            if (this.state?.availableAddons) {
                availableAddons = this.state.availableAddons.map(({...addon}) => {
                    let button = <button className="gh-btn gh-btn-sm" onClick={() => this.addAddonToCheckout(addon)}>Add</button>;

                    if (this.state?.selectedAddons && this.state?.selectedAddons.length && this.state.selectedAddons.find(selectedAddon => selectedAddon.name === addon.name)) {
                        // Disable the removed button when the site has an activated subdirectory
                        if (this.state?.hasSubdirectory && addon?.name?.match(/subdirectory/i)) {
                            button = <div className="gh-btn gh-btn-green gh-btn-sm disabled ">Active</div>;
                        } else {
                            button = <button className="gh-btn gh-btn-grey gh-btn-sm" onClick={() => this.removeAddonFromCheckout(addon)}>Remove</button>;
                        }
                    }

                    let {cost, billing_period: addonPeriod} = addon.prices.find(price => price.billing_period === this.state.period);

                    return (
                        <div key={addon.name} className="box-addon-item">
                            <div className="plandetails-split">
                                <h3>{addon.name}</h3>
                                <div>
                                    {this.state.period === 'month' ?
                                        <>
                                            <span>$</span>{formatNumber(monthlyPrice(cost, addonPeriod))}<small>/mo</small>
                                        </>
                                        :
                                        <>
                                            <span>$</span>{formatNumber(yearlyPrice(cost, addonPeriod))}<small>/year</small>
                                        </>
                                    }
                                </div>
                            </div>
                            <p className={`addon-desc ${this.state.showAddon[addon.name] ? 'open' : 'hidden'}`} dangerouslySetInnerHTML={{__html: addon?.description}}></p>
                            <footer>
                                <button className="addon-desc-toggle" onClick={() => this.toggleAddonDetails(addon.name)}>{this.state.showAddon[addon.name] ? 'Hide details \u2191' : 'Show details \u2192'}</button>
                                {button}
                            </footer>
                        </div>
                    );
                });
            }

            return (
                <main className="main main-med">

                    <div className="checkoutgrid" data-test-id="plan-summary">

                        <section className="checkoutgrid-main">
                            <div className="box-wrap">
                                <div className="box-label">Your plan summary</div>
                                <div className="box">
                                    <div className="plandetails">
                                        <div className="plandetails-content">
                                            <div className={`plandetails-split ${this.state.period === 'year' ? 'annual' : ''}`}>
                                                <h2>{this.state.selectedBaseProduct.name} ({formatPeriod(this.state.suitablePrice.billing_period)})</h2>
                                                <div>
                                                    {this.state.period === 'month' ?
                                                        <>
                                                            <span>$</span>{formatNumber(monthlyPrice(this.state.suitablePrice.cost, this.state.suitablePrice.billing_period))}<small>/mo</small>
                                                        </>
                                                        :
                                                        <>
                                                            <span>$</span>{formatNumber(yearlyPrice(this.state.suitablePrice.cost, this.state.suitablePrice.billing_period))}<small>/year</small>
                                                        </>
                                                    }

                                                </div>
                                            </div>
                                            <p>Base plan with {this.state?.selectedBaseProduct?.features?.filter(feature => feature.indexOf('Everything') < 0).join(', ').toLowerCase()}</p>
                                        </div>
                                        <div className="plandetails-selected">
                                            <div className="plandetails-selected-content">
                                                <strong>{formatNumber(this.state.membersIncluded)}</strong>
                                                <span>Members included</span>
                                            </div>
                                            <Link to="/plans" className="gh-btn gh-btn-outline" disabled={this.state.btnDisabled}>Change plan</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {availableAddons?.length > 0 &&
                                <div className="box-wrap">
                                    <div className="box-label">Optional add-ons</div>
                                    <div className="box box-addon">

                                        {availableAddons}

                                    </div>
                                </div>
                            }

                        </section>
                        <section className="checkoutgrid-side">

                            <div className="checkout-summary">
                                <div className="box-label">Billing summary</div>
                                <div className="box">
                                    <div className="checkout-summary-switch">
                                        <button className={this.state.period === 'month' ? 'active' : null} onClick={() => this.togglePeriod('month')} disabled={this.state.btnDisabled || !this.state?.comparablePrice}>Monthly</button>
                                        <button className={this.state.period === 'year' ? 'active' : null} onClick={() => this.togglePeriod('year')} disabled={this.state.btnDisabled || !this.state?.comparablePrice}>Yearly</button>
                                    </div>

                                    <div className="checkout-summary-card">
                                        <UpdateCardModal
                                            user={this.state?.user}
                                            customerId={this.state?.subscription?.customer_id || this.state?.user?.stripe_token}
                                            isGrace={this.state?.billingData?.isGrace}
                                            showCardUpdateModal={false}
                                            handleCloseModal={this.handleCloseModal}
                                            handleNestedLoadingState={this.handleNestedLoadingState}
                                            setModalIsOpen={this.handleOpenUpdateCardModal}
                                            modalIsOpen={this.state?.updateCardModalIsOpen}
                                        />

                                        <InvoiceDetailsModal
                                            setModalIsOpen={this.handleOpenInvoiceDetailsModal}
                                            handleCloseModal={this.handleCloseModal}
                                            customerId={this.state.subscription?.customer_id || this.state?.user?.stripe_token}
                                            invoiceDetails={this.state?.invoiceDetails}
                                            modalIsOpen={this.state?.invoiceDetailsModalIsOpen}
                                        />
                                    </div>

                                    <div className="checkout-summary-breakdown">

                                        {selectedAddons?.length > 0 && selectedAddons}

                                        {annualDiscount &&
                                            <>
                                                <div className="grey">
                                                    <span>Yearly price</span>
                                                    <span>{formatNumber(getMonthlyEquivalentPrice(this.state?.comparablePrice ?? this.state?.suitablePrice, this.state?.suitablePrice) * 12, {symbol: '$'})}</span>
                                                </div>
                                                <div className="green highlighted">
                                                    <span><strong>{getAnnualDiscount(this.state?.comparablePrice ?? this.state?.suitablePrice, this.state?.suitablePrice, {percentage: true})}%</strong> discount</span>
                                                    <span>-{formatNumber(annualDiscount, {symbol: '$'})}</span>
                                                </div>
                                            </>
                                        }
                                        <div>
                                            <span>Total per {this.state.period}</span>
                                            <strong>{formatNumber(getTotalPrice({cost: this.state.suitablePrice.cost, period: this.state.period, selectedAddons: this.state?.selectedAddons}), {symbol: '$'})}</strong>
                                        </div>
                                        {this.state?.user?.coupon && this.state?.discount?.coupon?.id && this.state?.discount?.coupon?.valid &&
                                            <div className="green highlighted">
                                                {this.state.discount.coupon?.name ?
                                                    <span><strong>{this.state.discount.coupon?.name}</strong> discount</span>
                                                    :
                                                    <span><strong>{this.state.discount.coupon?.id?.toUpperCase()}</strong> coupon</span>
                                                }
                                                <span>-{getCouponDiscount({coupon: this.state.discount.coupon, currentTotal: this.state.suitablePrice.cost, format: true})}</span>
                                            </div>
                                        }
                                        {this.state?.proration < 0 &&
                                            <div className="green highlighted">
                                                <span>Credit from last payment</span>
                                                <span>{formatNumber(this.state.proration, {symbol: '$'})}</span>
                                            </div>
                                        }
                                    </div>

                                    <div className="checkout-summary-cta">
                                        <div>
                                            <span>Amount to be billed now: </span>
                                            <span>{formatNumber(this.state.amountDue, {symbol: '$'})}</span>
                                        </div>
                                        <SpinnerButton {...this.state.buttonState} data-test-btn="checkout" />

                                        {this.state.errors &&
                                            <div className="form-error">
                                                {
                                                    this.state.errors?.match(/add a card/)
                                                        ? <button className="add-card-btn" onClick={() => this.handleOpenUpdateCardModal(true)}>{this.state.errors} &rarr;</button>
                                                        : <p className="error-msg">{this.state.errors}</p>
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>

                        </section>

                    </div>

                </main>
            );
        } else {
            return (
                <div className="gh-loading-content">
                    <div className="gh-loading-spinner"></div>
                </div>
            );
        }
    }
}

Checkout.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
        replace: PropTypes.func.isRequired
    }).isRequired,
    updateTitle: PropTypes.func.isRequired,
    showNav: PropTypes.func.isRequired
};

export default withRouter(Checkout);
