/**
 * @description Get the subscription status based on the subscription and the latest invoice.
 * @param {object} subscription
 * @param {string} subscription.status
 * @param {string} subscription.customer_id
 * @param {object} latestInvoice
 * @param {string} latestInvoice.status
 * @param {object} latestInvoice.payment_intent
 * @param {string} latestInvoice.payment_intent.status
 * @param {boolean} [verbose]
 * @returns {object}
 */
export default function getSubscriptionStatus(subscription, latestInvoice, verbose = false) {
    if (!subscription) {
        return {};
    }

    if (!subscription || !subscription?.status || !latestInvoice || !latestInvoice?.status) {
        return {};
    }

    /** @type {string} */
    const {status} = subscription;

    /** @type {boolean} */
    const _hasAutoPriceEnabled = !!subscription?.meta?.addons?.find(addon => addon?.name?.toLowerCase()?.indexOf('autobilling') >= 0);
    /** @type {boolean} */
    const _isTrial = status === 'trialing';
    /** @type {boolean} */
    const _isActive = status === 'active';
    /** @type {boolean} */
    const _isIncomplete = status === 'incomplete';
    /** @type {boolean} */
    const _isCancelled = status === 'canceled';
    /** @type {boolean} */
    const _isGrace = status === 'past_due' || status === 'unpaid';
    /** @type {boolean} */
    const _isInactive = _isCancelled || (!_isTrial && !_isActive && !_isGrace && !_isIncomplete);
    /** @type {boolean} */
    let _isPending = false;
    /** @type {boolean} */
    const requiresAction = latestInvoice?.payment_intent?.status === 'requires_action' || latestInvoice?.payment_intent?.status === 'requires_payment_method';
    /** @type {string} */
    const invoiceStatus = latestInvoice?.status;
    /** @type {boolean} */
    const invoiceRequiresAction = invoiceStatus === 'open' && requiresAction;
    const invoiceDueToSubscriptionCycle = latestInvoice?.billing_reason === 'subscription_cycle';

    // When the last invoice has the status 'open' it's a pending update and the user should have the option
    // of finalising it. If the pending update doesn't take effect, e. g. it expired after 24 hours, or the
    // user subscribed to a different plan, the invoice will be marked as void.
    if (
        ((status === 'active') || (_hasAutoPriceEnabled && status === 'past_due' && !invoiceDueToSubscriptionCycle))
        && invoiceRequiresAction
    ) {
        if (verbose) {
            if (_hasAutoPriceEnabled) {
                console.log('[API] Subscription status, invoice open from auto billing:', latestInvoice?.payment_intent?.status);
            } else {
                console.log('[API] Subscription status, invoice open:', latestInvoice?.payment_intent?.status);
            }
        }

        _isPending = true;
    }

    return {
        isTrial: _isTrial,
        isActive: _isActive,
        isIncomplete: _isIncomplete,
        isInactive: _isInactive,
        isGrace: _isGrace && (!_hasAutoPriceEnabled || invoiceDueToSubscriptionCycle),
        isPending: _isPending && !_hasAutoPriceEnabled,
        isAutoBillingGrace: _hasAutoPriceEnabled && _isPending,
        hasAutoBillingEnabled: _hasAutoPriceEnabled
    };
}
