import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {
    withRouter,
    Link
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import {
    getJWT,
    getPartialCachedBillingData,
    getCachedBillingData,
    setupSendingDomain,
    checkSendingDomain
} from '../data/api';
import SpinnerButton from './shared/SpinnerButton';
import DomainStatusField from './Domain/DomainStatusField';

import mailIcon from '../icons/mail.svg';

let _isMounted = false;
const activeStates = ['ACTIVE', 'CUSTOM', 'PROXY', 'VERIFIED'];
const failedStates = ['FAILED', 'INVALID', 'DEFUNCT'];

function Domain({
    showNav,
    updateTitle,
    history
}) {
    _isMounted = false;

    const pendingStates = ['PENDING', 'NONE', 'CUSTOMNEXT'];

    const buttonStates = {
        fail: {
            className: 'gh-btn gh-btn-block',
            html: 'Try again',
            disabled: false
        },
        pending: {
            className: 'gh-btn gh-btn-block spinner',
            html: '<span><div class="gh-spinner"></div></span>',
            disabled: true
        },
        default: {
            className: 'gh-btn gh-btn-block',
            html: 'Setup',
            disabled: false
        }
    };

    const [loading, setLoading] = useState(true);
    const [currentSite, setCurrentSite] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [showError, setShowError] = useState(false);
    const [buttonState, setButtonState] = useState(buttonStates.default);
    const [isPending, setIsPending] = useState(false);
    const [dnsRecords, setDnsRecords] = useState([]);
    const [isStarterPlan, setIsStarterPlan] = useState(false);

    const enableDomain = async (event) => {
        event.preventDefault();

        setButtonState(buttonStates.pending);

        try {
            let params = null;
            let res;
            if (currentSite?.sending_domain_status === 'INITIALIZED') {
                res = await checkSendingDomain({siteId: currentSite?.id});
            } else {
                res = await setupSendingDomain({siteId: currentSite?.id});
            }

            if (res?.errors) {
                const message = res.errors?.message || 'Unable to setup custom sending domain';

                Sentry.withScope((scope) => {
                    scope.setExtra('pointer', 'enableDomain');
                    scope.setExtra('currentSite', currentSite);
                    Sentry.captureMessage(message, 'warning');
                });

                setErrorMessage(message);
                setButtonState(buttonStates.fail);
                return;
            }

            // pre-populate the DNS records if they exist
            if (res?.mailgun_dns_records && res?.dmarc_dns_record) {
                params = [...res?.mailgun_dns_records];
                if (res?.dmarc_dns_record?.valid === 'unknown') {
                    params.push(res?.dmarc_dns_record);
                }
            }

            setButtonState(buttonStates.default);
            setDnsRecords(params);

            // add pass them to the next page to avoid another API call
            return history.push(`/domain/sending`, [...params]);
        } catch (error) {
            Sentry.captureException(error, {
                tags: {
                    pointer: 'enableDomain'
                }
            });
            setErrorMessage(error?.message || 'Unable to setup custom sending domain');
            setButtonState(buttonStates.fail);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            const cachedSiteData = await getPartialCachedBillingData('currentSite');
            const cachedBillingData = await getCachedBillingData();

            setCurrentSite(cachedSiteData);

            const scope = Sentry.getCurrentScope();
            scope.setTransactionName('Domain');
            Sentry.setUser({email: cachedBillingData?.user?.email_address});
            Sentry.setContext('billingData', {
                status: cachedBillingData?.subscription?.status,
                price: cachedBillingData?.currentPrice?.nickname,
                site: cachedBillingData?.currentSite?.url,
                exceededLimits: cachedBillingData?.exceededLimits,
                forceUpgrade: cachedBillingData?.isForceUpgrade
            });
            Sentry.setTag('section', 'domain');

            if (!cachedBillingData || cachedBillingData?.errors) {
                // Handle different states of errors or no permissions:
                //  - Legacy plans or multiple sites per user will resolve in a 403 status
                //  - Lapsed trial or subscriptions have a `forceUpgrade` state. If the current
                //    user is **not** the owner of the site, we let them know to inform the owner.
                //  - Any other error tells the user to contact support.
                setShowError(true);

                if (cachedBillingData?.errors === 403) {
                    setErrorMessage(<>You’re on a special legacy plan. If you need to make any billing or domain changes, reach out to us on <a href="mailto:support@ghost.org" onClick={contactSupport}>support@ghost.org</a></>);
                } else if (cachedBillingData?.errors === 408) {
                    setErrorMessage(<>The request timed out. Try again or contact <a href="mailto:support@ghost.org" onClick={contactSupport}>support@ghost.org</a>.</>);
                } else {
                    setErrorMessage(<>Contact <a href="mailto:support@ghost.org" onClick={contactSupport}>support@ghost.org</a> for changes to your domain or billing.</>);
                }
                return history.push('/');
            }

            // Site with a lapsed trial or subscription are in a `forceUpgrade` state.
            // If the current user is the owner of the site, redirect them to the
            // plan selection to checkout a new subscription.
            if (cachedBillingData?.forceUpgradeOwner) {
                showNav(true);
                return history.push('/plans');
            }
            if (cachedBillingData?.isGrace) {
                showNav(true);
                // Return to Dashboard when user is in dunning to update card details
                return history.push('/');
            }

            if (cachedBillingData?.isIncomplete || cachedBillingData?.isAutoBillingGrace) {
                // Redirect back to Checkout with the current plan to finish the payment
                return history.push(cachedBillingData.checkoutRoute);
            }

            if (pendingStates.includes(cachedSiteData?.ssl_status)) {
                setIsPending(true);
            } else {
                setIsPending(false);
            }

            if (cachedBillingData?.currentPrice?.base_product?.toLowerCase() === cachedBillingData?.availableProducts?.[0]?.name.toLowerCase()) {
                // Customers on Starter plan will not be able to access the sending domain settings...
                setIsStarterPlan(true);
            }

            // If the sending domain has some status, prefetch the DNS records
            // and pass them to the next page to avoid another API call
            if (!cachedSiteData?.sending_domain_status === 'INITIALIZED'
                || cachedSiteData?.sending_domain_status === 'PENDING'
                || cachedSiteData?.sending_domain_status === 'VERIFIED'
                || cachedSiteData?.sending_domain_status === 'INVALID') {
                try {
                    const checkedSendingDomain = await checkSendingDomain({siteId: cachedSiteData?.id});

                    if (checkedSendingDomain?.mailgun_dns_records && checkedSendingDomain?.dmarc_dns_record) {
                        const records = [...checkedSendingDomain.mailgun_dns_records];

                        // skip the DMARC record if it's already valid and verified
                        if (checkedSendingDomain?.dmarc_dns_record?.valid === 'unknown') {
                            records.push(checkedSendingDomain?.dmarc_dns_record);
                        }

                        setDnsRecords(records);
                    }
                } catch (error) {
                    // noop
                    Sentry.withScope((errorScope) => {
                        errorScope.setExtra('pointer', 'checkSendingDomain');
                        errorScope.setExtra('currentSite', cachedSiteData);
                        Sentry.captureMessage(error, 'warning');
                    });
                }
            }

            updateTitle('Domain settings');
            setLoading(false);
            showNav(true);
        };

        showNav(false);

        if (!_isMounted) {
            _isMounted = true;

            fetchData();
        }
        return () => {
            _isMounted = false;
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const contactSupport = (event) => {
        event.preventDefault();

        return window.location.href = 'mailto:support@ghost.org';
    };

    const handleEditSubdomain = (event) => {
        event.preventDefault();

        // We need to load the token here to use it as query param when
        // updating the ghost.io subdomain and ensure it's fresh and not expired from he cache
        getJWT().then(token => (window.parent.location.href = `${process?.env?.REACT_APP_DOMAIN_APP}?jwt=${token}`));
    };

    return (
        <main className="main">

            <div className="domaingrid" data-test-id="dashboard">

                {loading ?
                    (
                        <div className="gh-loading-content">
                            <div className="gh-loading-spinner"></div>
                        </div>
                    ) :
                    (showError) ?
                        (
                            <p style={{textAlign: 'center', gridColumn: '1 / 3'}}>{errorMessage}</p>
                        ) :
                        <>
                            {!activeStates.includes(currentSite?.ssl_status) &&
                            <div className="box-domain box-wrap">
                                <div className="box">
                                    <section className="staticfield">
                                        <header>
                                            <div className="staticfield-label">Ghost.io Domain</div>
                                            <button className="staticfield-link" onClick={handleEditSubdomain}>Edit</button>
                                        </header>
                                        <div className="staticfield-value">{currentSite?.subdomain || 'yourdomain'}.{currentSite?.pro_domain || 'ghost.io'}</div>
                                    </section>
                                </div>
                            </div>
                            }

                            {currentSite?.external_domain ?
                                <>
                                    <div className="box-domain box-wrap">
                                        <div className="box">
                                            <section className="staticfield">
                                                <header>
                                                    <div className="staticfield-label">Custom Domain</div>
                                                </header>
                                                {failedStates.includes(currentSite?.ssl_status)
                                                    ? <p>Your custom failed to activate! Check the DNS records.</p>
                                                    : !isPending && <p>The website address for your publication</p>
                                                }

                                                <DomainStatusField
                                                    status={currentSite?.ssl_status}
                                                    domain={currentSite?.external_domain}
                                                    type="custom-domain"
                                                    linkTo="/domain/custom"
                                                />
                                            </section>
                                        </div>
                                    </div>

                                    {/* Only show the custom sending domain section with a successfully activated */}
                                    {(currentSite?.ssl_status === 'ACTIVE' && currentSite?.sending_domain_status !== 'CUSTOM' && !isStarterPlan) ?

                                        (currentSite?.sending_domain_status === 'VERIFIED'
                                        || currentSite?.sending_domain_status === 'PENDING'
                                        || currentSite?.sending_domain_status === 'INVALID'
                                        )
                                            ?
                                            <div className="box-domain box-wrap">
                                                <div className="box">
                                                    <section className="staticfield">
                                                        <header>
                                                            <div className="staticfield-label">Custom Sending Domain</div>
                                                        </header>
                                                        {currentSite?.sending_domain_status === 'INVALID'
                                                            ? <p className="red">We've detected a problem with your sending domain that may impact email deliverability for your newsletter</p>
                                                            : currentSite?.sending_domain_status === 'PENDING'
                                                                ? <p>Your custom sending domain is pending to be verified</p>
                                                                : <p>The domain you use to send email newsletters</p>
                                                        }

                                                        <DomainStatusField
                                                            status={currentSite?.sending_domain_status}
                                                            domain={currentSite?.external_domain}
                                                            type="custom-sending-domain"
                                                            linkTo="/domain/sending"
                                                            params={dnsRecords}
                                                        />
                                                    </section>
                                                </div>

                                            </div>
                                            : (!currentSite?.sending_domain_status
                                                || currentSite?.sending_domain_status === 'NONE'
                                                || currentSite?.sending_domain_status === 'INITIALIZED'
                                                || currentSite?.sending_domain_status === 'DEFUNCT'
                                            ) &&
                                                    <div className="box-domain box-wrap">
                                                        <div className="box">
                                                            <section className="staticfield">
                                                                <header>
                                                                    <div className="staticfield-label">Custom Sending Domain</div>
                                                                </header>
                                                                {currentSite?.sending_domain_status === 'DEFUNCT'
                                                                    ? <p className="red">Your custom sending domain has been removed due to missing DNS records.</p>
                                                                    : <p>Customize the from-address of your email newsletter with a custom sending domain.</p>
                                                                }

                                                                <div className="sending-domain">
                                                                    <div className="sending-domain-rapper">
                                                                        <strong>Default sending domain</strong>
                                                                        <div className="sending-domain-box">
                                                                            <img src={mailIcon} className="domain-status-icon" alt="Email" />
                                                                            <strong>From:</strong>
                                                                            <span>{currentSite?.subdomain}@ghost.io</span>
                                                                        </div>
                                                                    </div>

                                                                    <div className="sending-domain-arrow">&rarr;</div>
                                                                    <div className="sending-domain-rapper">
                                                                        <strong>Custom sending domain</strong>
                                                                        <div className="sending-domain-box">
                                                                            <img src={mailIcon} className="domain-status-icon" alt="Email" />
                                                                            <strong>From:</strong>
                                                                            <span>name@{currentSite?.external_domain?.replace(/^www\./, '')}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <SpinnerButton
                                                                    onClick={enableDomain}
                                                                    className={buttonState.className}
                                                                    html={buttonState.html}
                                                                    disabled={buttonState.disabled}
                                                                    data-test-btn="setup-sending-domain"
                                                                />
                                                            </section>
                                                        </div>
                                                    </div>
                                        : null
                                    }

                                </>
                                :
                                <div className="box-domain box-wrap">
                                    <div className="box">
                                        <section className="staticfield">
                                            <header>
                                                <div className="staticfield-label">Custom Domain</div>
                                            </header>
                                            <Link className="gh-btn gh-btn-block" data-test-btn="custom-domain" to="/domain/custom">Setup custom domain</Link>
                                        </section>
                                    </div>
                                </div>
                            }
                        </>

                }

            </div>

        </main>
    );
}

Domain.propTypes = {
    showNav: PropTypes.func,
    updateTitle: PropTypes.func,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    })
};

export default withRouter(Domain);
